import React from 'react';
import CopyToClipboard from 'react-copy-html-to-clipboard';
import './App.css';
import qs from 'qs';


class Test extends React.Component {
    constructor(props){
        super(props);
        this.progressMsg = React.createRef();
        this.state = { 
            pings:[],
            progress: '',
            results: [],
            downloads: [],
            buttons: false,
            start: false,
            value: '',
            showFinishedMsg: false,
            downloadSize: 5245329, //bytes
            currentServers : [],
            currentIndex : 0,
            iterations : 6,
            servers :[
                {
                    name: "IN-1",
                    url: "https://in-1.voip.toky.co/cld_4.svg",
                    image_url: "https://in-1.voip.toky.co/sr_5mb.jpg"
                },
                {
                    name: "DE-1",
                    url: "https://de-1.voip.toky.co/cld_4.svg",
                    image_url: "https://de-1.voip.toky.co/sr_5mb.jpg"
                },
                {
                    name: "DE-2",
                    url: "https://de-2.voip.toky.co/cld_4.svg",
                    image_url: "https://de-2.voip.toky.co/sr_5mb.jpg",
                },
                {
                    name: "DE-4",
                    url: "https://de-4.voip.toky.co/cld_4.svg",
                    image_url: "https://de-4.voip.toky.co/sr_5mb.jpg",
                },
                {
                    name: "DE-5",
                    url: "https://de-5.voip.toky.co/cld_4.svg",
                    image_url: "https://de-5.voip.toky.co/sr_5mb.jpg",
                },
                {
                    name: "US-3",
                    url: "https://us-3.voip.toky.co/cld_4.svg",
                    image_url: "https://us-3.voip.toky.co/sr_5mb.jpg"
                },
                {
                    name: "US-5",
                    url: "https://us-5.voip.toky.co/cld_4.svg",
                    image_url: "https://us-5.voip.toky.co/sr_5mb.jpg"
                },
                {
                    name: "US-6",
                    url: "https://us-6.voip.toky.co/cld_4.svg",
                    image_url: "https://us-6.voip.toky.co/sr_5mb.jpg"
                },
                {
                    name: "BR-1",
                    url: "https://br-1.voip.toky.co/cld_4.svg",
                    image_url: "https://br-1.voip.toky.co/sr_5mb.jpg"
                },
                {
                    name: "BR-2",
                    url: "https://br-2.voip.toky.co/cld_4.svg",
                    image_url: "https://br-2.voip.toky.co/sr_5mb.jpg"
                },
                {
                    name: "BR-3",
                    url: "https://br-3.voip.toky.co/cld_4.svg",
                    image_url: "https://br-3.voip.toky.co/sr_5mb.jpg"
                },
                {
                    name: "SG-2",
                    url: "https://sg-2.voip.toky.co/cld_4.svg",
                    image_url: "https://sg-2.voip.toky.co/sr_5mb.jpg"
                },
                {
                    name: "SG-4",
                    url: "https://sg-4.voip.toky.co/cld_4.svg",
                    image_url: "https://sg-4.voip.toky.co/sr_5mb.jpg"
                },
                {
                    name: "AU-1",
                    url: "https://au-1.voip.toky.co/cld_4.svg",
                    image_url: "https://au-1.voip.toky.co/sr_5mb.jpg"
                }
            ]
        };
    }
    componentDidMount(){
        this.startAllTests(); 
    }
    
    componentDidUpdate(prevProps) {
        if (this.state.copied === true) {
            setTimeout(()=> {
                this.setState(state => ({
                    copied: false,
                }));
           }, 5000);
        }

        if (prevProps.start !== this.props.start) {
            this.startAllTests(); 
        }
        // scroll progressMsg to current view in browser
        this.progressMsg.current.scrollIntoView({ behavior: 'smooth' });
    }
    

    restartAllTests = () => {
        if(this.state.buttons){
            this.setState(state => ({
                pings:[],
                progress: '',
                results: [],
                buttons: false,
                start: false,
                downloads: [],
                value: '',
                showFinishedMsg: false,
                copied: false,
                currentIndex : 0,
                iterations : 6,
            }));
            this.startAllTests(); 
        }
    }

    startAllTests = () => {
        let servers = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).servers;
        let pings = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).pings;
        if(!isNaN(pings)){
            this.setState(state => ({iterations: pings}));
        }
        let i, j; 
        let v_currentServers =[];
        if(servers === undefined){
            for (i = 0; i < this.state.servers.length; i++) {
                v_currentServers.push(i);
            }
        } else {
            var serversArray = servers.split(',');
            for (j = 0; j < serversArray.length; j++) {
                for (i = 0; i < this.state.servers.length; i++) {
                    if(serversArray[j].toLowerCase()===this.state.servers[i].name.toLowerCase()){
                        v_currentServers.push(i);
                    }
                }
            }
        }

        this.setState({currentServers: v_currentServers}, function () {
            this.startPingTest();
        });
    }

    startPingTest = () => {
        let index = this.state.currentIndex;
        if(this.state.currentServers[index] !== undefined){
            let indexOnServersArray = this.state.currentServers[index];
            let serverName = this.state.servers[indexOnServersArray].name;
    
            let result ={
                serverName: serverName,
                pings:[],
                partial:''
            };
    
            let resultsArray = this.state.results;
            resultsArray.push(result);
            
            this.setState(state => ({
                results: resultsArray,
                value: state.value + "HTTP ping for " + serverName + "\n",
                progress: 'Please wait, testing connection to ' + serverName
            }));
            this.httpPing(this.state.servers[indexOnServersArray].url, index);
        }else{
            this.setState({
                currentIndex: 0,
            }, function () {
                this.startDownloadTest();
            });
        }
    }

    startDownloadTest = () => {
        let index = this.state.currentIndex;
        if(this.state.currentServers[index] !== undefined){
            this.setState({
                progress: 'Loading an image from '+ this.state.servers[this.state.currentServers[index]].name + " please wait...",
            }, function () {
                this.downTest(index);
            });
        }else{
            this.setState({
                showFinishedMsg: true,
                buttons: true,
                progress: '',
            });
        }

    }

    downTest = (index)=>{
        let currentServer = this.state.currentServers[index];
        let downloadsArray = this.state.downloads;
        let serverName = this.state.servers[currentServer].name;
        var startTime, endTime;
        var download = new Image();

        download.onload = () => {
            endTime = (new Date()).getTime();
            this.showResults();
        }

        startTime = (new Date()).getTime();
        var cacheBuster = "?nnn=" + startTime;
        
        download.src = this.state.servers[currentServer].image_url + cacheBuster;
        
        download.onerror = (err, msg) => {
            downloadsArray.push({"serverName" : serverName, "speedMbps" : "Invalid image, or error downloading"});
        }
        var speedMbps;
        this.showResults = () => {
            var duration = (endTime - startTime) / 1000;
            var bitsLoaded = this.state.downloadSize * 8;
            var speedBps = (bitsLoaded / duration).toFixed(2);
            var speedKbps = (speedBps / 1024).toFixed(2);
            speedMbps = (speedKbps / 1024).toFixed(2);
            downloadsArray.push({"serverName" : serverName, "speedMbps" : speedMbps+" Mbps"});
            this.setState({
                value: this.state.value + "Your connection speed to server  " + serverName + " is: \n" + speedMbps + " Mbps\n\n",
                downloads: downloadsArray,
                currentIndex: this.state.currentIndex + 1,
            }, function () {
                this.startDownloadTest();
            });
        }
    }
    // https://stackoverflow.com/questions/4954741/how-to-ping-ip-addresses-using-javascript/17059424
    httpPing = (fqdn, index) => {
        var NB_ITERATIONS = this.state.iterations; // number of loop iterations
        var MAX_ITERATIONS = parseInt(this.state.iterations)+1; // beware: the number of simultaneous XMLHttpRequest is limited by the browser!
        var TIME_PERIOD = 1000; // 1000 ms between each ping
        var i = 0;
        var over_flag = 0;
        var time_cumul = 0;
        var losts = 0;
        var REQUEST_TIMEOUT = 6000;
        
        let pingsArray = [];
        
        var ping_loop = setInterval(() => {
            let resultsArray = this.state.results;
            // let's change non-existent URL each time to avoid possible side effect with web proxy-cache software on the line
            let url = fqdn + "?x=" + Math.random().toString(36).substring(7);
            if (i < MAX_ITERATIONS) {
                var ping = new XMLHttpRequest();
                i++;
                ping.seq = i;
                over_flag++;
                ping.date1 = Date.now();
                ping.timeout = REQUEST_TIMEOUT; // it could happen that the request takes a very long time
                ping.onreadystatechange = ()=> { // the request has returned something, let's log it (starting after the first one)
                    if (ping.readyState === 4 && ping.status === 200) {
                        over_flag--;
                        if (ping.seq > 1) {
                            let delta_time = Date.now() - ping.date1;
                            time_cumul += delta_time;
                            resultsArray[index].pings.push("http_seq=" + (ping.seq-1) + " time=" + delta_time + " ms");
                            this.setState({
                                value: this.state.value + "http_seq=" + (ping.seq-1) + " time=" + delta_time + " ms\n",
                                results: resultsArray,
                            });
                        }
                    } else if (ping.readyState === 4) {
                        over_flag--;
                        if (ping.seq > 1) {
                            losts++;
                            resultsArray[index].pings.push("Request timed out.");
                            this.setState({
                                value: this.state.value + "Request timed out.\n",
                                results: resultsArray,
                            });
                        }
                    }
                }
                ping.ontimeout = () => {
                    over_flag--;
                    if (ping.seq > 1) {
                        pingsArray.pop();
                        pingsArray.push("THERE WAS A TIMEOUT ERROR");
                    }
                }
                ping.open("GET", url, true);
                ping.send();
            }
    
            if ((i > NB_ITERATIONS) && (over_flag < 1)) { // all requests are passed and have returned
                clearInterval(ping_loop);
                var avg_time = Math.round(time_cumul / (i - 1));
                let partial = "Average ping latency on " + (i-1) + " iterations: " + avg_time + "ms.";
                if(losts>0){
                    let lostAvg = Math.round((losts*100)/(i-1));
                    partial = partial + " Lost: " + losts + " ("+lostAvg+"% loss)."
                }resultsArray[index].partial = partial;
                this.setState({
                    value: this.state.value + partial +"\n\n",
                    currentIndex: this.state.currentIndex + 1,
                }, function () {
                    this.startPingTest();
                });
            }
        }, TIME_PERIOD);
    }

    render() {
        return (
            <div className="container">
                <header className="head">
                    <a className="logo" href="# ">
                        <img src="/resources/images/logowhite.svg" alt="Toky logo" />
                    </a>
                    <h1>
                        Connection Quality Test
                    </h1>
                </header>
                <section className="term" >
                    <div id="term" className="term-content">
                        <h2>Starting tests, please wait and check at the bottom to see if the test has finished</h2>
                        
                        {this.state.results.length > 0 ? this.state.results.map((result,i) => {
                            return (
                                <div key={"ping-div-"+i}>
                                    <p>HTTP ping for {result.serverName}</p>
                                    {result.pings.map((ping,id) => {
                                        return(<p key={"ping-" + id}>{ping}</p>);
                                    })}
                                    <p className='partial'>{result.partial}</p>
                                <br/>
                                </div>
                            );
                        }) : ""}

                        {this.state.downloads.length > 0 ? this.state.downloads.map((download,i) => {
                            return (
                                
                                <div key={"down-div-"+i}>
                                    <p>Your connection speed to server {download.serverName} is:</p>
                                    <p>{download.speedMbps}</p>
                                    <br/>
                                </div>
                            );
                        }) : ""}
                    
                    <p ref={ this.progressMsg }>{this.state.progress}</p>
                    <h2 style={{display: this.state.showFinishedMsg ? 'block' : 'none' }} >Test finished. Please 
                        <CopyToClipboard text={this.state.value}
                            onCopy={() => this.setState({copied: true})}>
                            <a href="# " className="cta"> <img className="ico" src="/resources/images/ico/cp.svg" alt="copy" /> copy the test results </a>
                        </CopyToClipboard> 
                        and send them to <a href='mailto:support@toky.co'>support@toky.co</a>. Please use Subject: [YOUR TOKY ACCOUNT EMAIL] Tests Results</h2>

                    <div className="alert alert-success" style={{display: this.state.copied ? 'block' : 'none' }}>
                        Copied to clipboard!
                    </div>
                    </div>  
                </section>

                <div className="action action-bottom">
                    <CopyToClipboard text={this.state.value}
                        onCopy={() => this.setState({copied: true})}>

                        <a href="# " className="btn btn-primary">Copy to clipboard</a>
                    </CopyToClipboard>
                    <a href="# " className={"btn btn-default" + (!this.state.buttons ? " btn-disabled" : '')}  onClick={this.restartAllTests}>Run Test again</a>
                </div>
            </div>
        );
    }
}
export default Test;