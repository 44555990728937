import React from 'react';
import Start from './Start.js';
import Test from './Test.js';
import './App.css';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = { 
        start: false,
    }
  }

  handleClick = (e) =>{
    this.setState(state=>({
        start: true,
    }));
  }

  render() {
    

    return (
      <div className="App">
        <div className="wrapper">
        <Router>
          <div>
          <Switch>
            <Route exact path="/" component={Start} />
            <Route path="/test" component={Test} />
          </Switch>
          </div>
        </Router>
        </div>
      </div>
    );
  }
}

export default App;
