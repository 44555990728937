import React from 'react';
import { Link } from 'react-router-dom'

class Start extends React.Component {
 

    render() {
        return (
            <div className="container container-small">
                <header className="head">
                    <a className="logo" href="# ">
                        <img src="/resources/images/logowhite.svg" alt="Toky logo" />
                    </a>
                    <h1>
                        Connection Quality Test
                    </h1>
                </header>
                <div className="action">
                    <Link className="btn btn-success" to="/test">Start test</Link>
                </div>
            </div>
        );
      }
}
export default Start;